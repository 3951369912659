<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div>
        <img v-for="(item,index) in masterinfo.listDetails" :key="index" :src="item.imgUrl"/>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
export default {
    components:{
        vHeader
    },
    data(){
        return{
            title: this.$route.meta.title,
            masterinfo:{}
        }
    },
    methods:{
        async getfamousDetail(){
            let data = {
                hongren_id: this.$route.query.id
                }
            const res = await this.$HTTP.get(this, this.$API['API_FAMOUS_DETAIL'], data, {headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    // console.log('名人信息',res)
                                    this.masterinfo=res.data
                                }
        }
    },
    mounted(){
        this.getfamousDetail()
    }

}
</script>

<style lang="less" scoped>
img{
    width: 100%;
}
</style>