import { render, staticRenderFns } from "./famousDetail.vue?vue&type=template&id=7ef3fa50&scoped=true&"
import script from "./famousDetail.vue?vue&type=script&lang=js&"
export * from "./famousDetail.vue?vue&type=script&lang=js&"
import style0 from "./famousDetail.vue?vue&type=style&index=0&id=7ef3fa50&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef3fa50",
  null
  
)

export default component.exports